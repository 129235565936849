import { createStore } from 'solid-js/store';

import { UserDetailStoreType, UserDetailsType } from './types';

const [userDetailStore, setUserDetailStore] = createStore<UserDetailStoreType>({
  isLoading: false,
  userDetails: {} as UserDetailsType,
  currentUser: true,
  documents: [],
  postingGroup: [],
});

export { userDetailStore, setUserDetailStore };
