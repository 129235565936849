import { dateComparator, dateFormatter } from '@store/loadboard/utils';
import { ColDef } from '@ag-grid-community/core';
const commonProps: ColDef = {
  enableRowGroup: true,
  resizable: true,
  autoHeight: true,
  cellStyle: {
    padding: '10px',
    'word-break': 'break-word',
    'white-space': 'normal',
    'line-height': '1.6 ',
    'align-items': 'center !important',
    cursor: 'pointer',
  },
};
const baseColumnDefs: ColDef[] = [
  {
    field: 'office',
  },
  {
    field: 'loadNumber',
  },
  {
    field: 'loadStatus',
  },
  {
    field: 'carrier',
  },
  {
    field: 'customer',
  },
  {
    field: 'agent',
  },
  {
    field: 'date',
    filter: 'agDateColumnFilter',
    valueFormatter: dateFormatter,
    comparator: dateComparator,
  },
  {
    field: 'status',
  },
  {
    field: 'claimDescription',
    headerName: 'Description',
    minWidth: 280,
  },
  {
    field: 'claimValue',
    headerName: 'Value',
    valueFormatter: (params: { value: number }) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(params.value);
    },
  },
];
export const adminColDefs: ColDef[] = baseColumnDefs.map((col) => {
  if (col.field === 'office') {
    return {
      ...col,
      rowGroup: true,
      hide: true,
      sort: 'asc',
      ...commonProps,
    };
  }
  if (col.field === 'agent') {
    return {
      ...col,
      rowGroup: false,
      sort: undefined,
      hide: false,
      ...commonProps,
    };
  }
  return { ...col, ...commonProps };
});
export const colDefs: ColDef[] = baseColumnDefs.map((col) => {
  if (col.field === 'agent') {
    return {
      ...col,
      rowGroup: true,
      hide: true,
      sort: 'asc',
      ...commonProps,
    };
  }
  if (col.field === 'office') {
    return {
      ...col,
      rowGroup: false,
      hide: true,
      sort: undefined,
      ...commonProps,
    };
  }
  return { ...col, ...commonProps };
});
export const capacityColDefs: ColDef[] = baseColumnDefs.map((col) => {
  if (col.field === 'customer') {
    return { ...col, hide: true, ...commonProps };
  }
  return { ...col, ...commonProps };
});
